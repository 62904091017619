<template>
  <!-- 
    Stop dialog close so users need to click the button to close it,
    otherwise you can generate the credentials and fail to copy them
   -->
  <v-dialog id="dialog" v-model="dialog" max-width="750px" persistent>
     <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="primary--text">
        <v-icon class="mr-1">add_box</v-icon>
        Create Service Account
      </v-btn>
    </template>
    <!-- Show create form -->
    <v-card v-if="!createdServiceAccount && !createdServiceAccountAccessKey">
      <v-card-title>Create New Service Account</v-card-title>
      <v-card-text>
        <form v-on:submit.prevent="createServiceAccount()">
           <v-text-field
            label="Service account name" 
            v-model="serviceAccount.name" 
            />
          <v-text-field
            label="Service account description" 
            v-model="serviceAccount.description" 
            />  
            <v-select
              v-model="serviceAccount.roleIds"
              :items="roles"
              :menu-props="{ maxHeight: '400' }"
              label="Roles"
              item-text="name"
              item-value="id"
              multiple
              hint="Select one or more roles that the new member will get"
              persistent-hint
            ></v-select>
          
          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <div v-show="error">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="resetForm()" class="primary--text">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!createdServiceAccount" text :disabled="loading" :loading="loading" v-on:click.native.stop="createServiceAccount()" class="primary">Submit</v-btn>
      </v-card-actions>
    </v-card>
  
    <!-- Once created, showing the key and commands -->
    <v-card v-if=createdServiceAccountAccessKey >
      <v-card-title>Service Account and Access Key Created</v-card-title>
      <v-card-text>
        Your access key:
        <v-container fluid>
            <markup language="bash" :code="createdServiceAccountAccessKey.value" :inline="false"></markup>
         </v-container>
      </v-card-text>

      <v-card-text>
          <p>
            <em>Please note that once you close this window you <b>will not</b> be able to see the access key again as it
              is now encrypted. If you lose your access key, just generate a new one.</em>
          </p>
          List the devices via API:
          <markup language="bash" :code="exampleCurlListDevices" :inline="false"></markup>
          List the applications via API:  
          <markup language="bash" :code="exampleCurlListApplications" :inline="false"></markup>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click.native="resetForm()" class="primary--text">Close, I have copied credentials</v-btn>
      </v-card-actions>
    </v-card>


  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>
import Markup from '../helpers/Markup';

  export default {
    components: {
      Markup
    },
    data () {
      return {
        dialog: false,
        serviceAccount: {
          id : '',
          name: '',
          description: '',
          roleIds: [],
        },
        serviceAccountAccessKey:{
          description : '',
        },
        exampleCurlListDevices: '',
        exampleCurlListApplications: '',
        projectId: '',
      }
    },
    computed: {
      loading() {
        return this.$store.state.iam.loading
      },
      roles() {
        return this.$store.state.role.roles
      },
      error() {
        return this.$store.state.iam.error
      },
      createdServiceAccount() {
        return this.$store.state.iam.createdServiceAccount
      },
      createdServiceAccountAccessKey() {
        return this.$store.state.iam.createdServiceAccountAccessKey
      },
      selectedProjectId () {
        return this.$store.state.project.selectedProjectId
      },
    },
    mounted() {
      this.refresh()
    },
    methods: {
      refresh(){
      let q = {
        projectId: this.selectedProjectId,
      }
      this.$store.dispatch('ListRoles', q)
      },
      createServiceAccount () {
        let q = {
          projectId: this.selectedProjectId,
          serviceAccount: this.serviceAccount,
        }
        this.$store.dispatch('CreateServiceAccount', q).then(() => {
            let q = {
              projectId: this.selectedProjectId,
              serviceAccount: this.createdServiceAccount,
              serviceAccountAccessKey: this.serviceAccountAccessKey,
            }

            if (q.serviceAccountAccessKey.description == '') {
              q.serviceAccountAccessKey.description = 'Service account for ' + q.serviceAccount.name
            }

            this.$store.dispatch('CreateServiceAccountAccessKey', q).then(() => {
              this.$store.dispatch('ListServiceAccounts', q)
              this.exampleCurlListDevices = "curl -u "+this.createdServiceAccountAccessKey.value+": https://cloud.synpse.net/api/projects/${PROJECT_ID}/devices " 
              this.exampleCurlListApplications = "curl -u "+this.createdServiceAccountAccessKey.value+": https://cloud.synpse.net/api/projects/${PROJECT_ID}/namespaces/${NAMESPACE_ID}/applications " 
            })
        })
      },

      resetForm: function () {
        // closing dialog
        this.dialog = false
        this.$store.dispatch('CleanupCreatedServiceAccount')
        this.$store.dispatch('CleanupCreatedServiceAccountAccessKey')
        // resetting form
        this.serviceAccount.description = ''
        this.serviceAccount.name = ''
        this.serviceAccount.roles = []
        this.serviceAccountAccessKey.description = ''

      }
    }
  }
</script>
